<template>
  <sheet-filters
    v-model="obFilterData"
    :model-name="modelClassName"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="4" lg="2">
          <v-switch
            v-model="obFilterData.active"
            :label="$t('active')"
            :value="1"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col cols="12" md="4">
          <search-field v-model="obFilterData.name" clearable @save="apply" />
        </v-col>

        <v-col cols="12" md="3">
          <customer-select
            v-model="obFilterData.customer_id"
            clearable
            @change="apply"
          />
        </v-col>

        <v-col cols="12" md="3">
          <product-select
            v-model="obFilterData.product"
            clearable
            @change="apply"
          />
        </v-col>
      </v-row>
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ContractsMixin from "@/modules/contracts/mixins/ContractsMixin";
import { AppModule } from "@/store/app";
import { get } from "lodash";
import SearchField from "@/components/form/fields/Search.vue";
import SheetFilters from "@/components/form/SheetFilters.vue";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import ProductSelect from "@/modules/products/components/ProductSelect.vue";

@Component({
  components: { ProductSelect, CustomerSelect, SheetFilters, SearchField },
})
export default class ContractFilters extends Mixins(ContractsMixin) {
  obFilterData: Record<string, any> = {};

  onMounted() {
    this.obFilterData = get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");

    this.applyFilters();
  }
}
</script>
