<template>
  <module-container
    :show-toolbar="showToolbar"
    :title="$t('contracts')"
    :dense="showToolbar"
    route-name="contracts"
  >
    <template #buttons>
      <create-contract-invoices-dialog />
    </template>

    <template #route>
      <router-view />
    </template>

    <template v-if="showToolbar" #filters>
      <contract-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ContractFilters from "@/modules/contracts/components/ContractFilters.vue";
import CreateContractInvoicesDialog from "@/modules/contracts/components/CreateContractInvoicesDialog.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { CreateContractInvoicesDialog, ContractFilters },
})
export default class ContractsIndex extends Mixins(PaginateProviderMixin) {
  get showToolbar() {
    return this.$route.name === "contracts.list";
  }
}
</script>
